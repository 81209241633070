import React from "react"
import { Link } from "gatsby"

import IconBack from "../svg/IconBack"
import IconReset from "../svg/IconReset"
import IconInfo from "../svg/IconInfo"
import IconCopyright from "../svg/IconCopyright"

import * as headerStyles from "./header.module.scss"

export default function Header({
  projectContent,
  title,
  contentStatusDescription,
  contentStatusGallery,
  contentStatusDrawings,
  contentStatusPrecedents,
  resetTabs,
  toggleModalInfo,
  toggleModalCredit,
}) {

  let contentStatus = false

  contentStatusDescription && (contentStatus = true)
  contentStatusGallery && (contentStatus = true)
  contentStatusDrawings && (contentStatus = true)
  contentStatusPrecedents && (contentStatus = true)

  return (
    <header className={headerStyles.center}>
      <div className={headerStyles.container}>
        <div>
          {projectContent === true ? (
            <div className={headerStyles.project_tools}>
              <Link to="/">
                <IconBack />
              </Link>
              {contentStatus &&
                <button className={headerStyles.reset} onClick={resetTabs}>
                  <IconReset />
                </button>
              }
            </div>
          ) : (
            <div>&nbsp;</div>
          )}
        </div>

        <div className={headerStyles.project_title}>
          {projectContent === true ? (
            <h1 className="color_cycle">{title}</h1>
          ) : (
            <div>&nbsp;</div>
          )}
        </div>

        <div className={headerStyles.nav_items}>
          <button onClick={toggleModalInfo}>
            <IconInfo />
          </button>
          <button onClick={toggleModalCredit}>
            <IconCopyright />
          </button>
        </div>
      </div>
    </header>
  )
}
