import React from "react"
import Modal from "react-modal"

import IconClose from "../../svg/IconClose"

import * as modalStyles from "./modal.module.scss"

export default function InfoModal({ modalInfoState, modalInfoSet }) {
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    modalInfoSet(false)
  }

  return (
    <Modal
      isOpen={modalInfoState}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className={modalStyles.modal}
      overlayClassName={modalStyles.overlay}
      contentLabel="Info Modal"
      ariaHideApp={false}
    >
      <div className={modalStyles.header}>
        <div className={modalStyles.close}>
          <button onClick={closeModal}>
            <IconClose />
          </button>
        </div>
      </div>

      <div className={modalStyles.content}>
        <h1>Who are we?</h1>
        <p>
          Makan is an experimental design collective, established in 2016
          by Steav Nissan, Darren Dharmadasa and Frank Burridge. Having diverse
          educational backgrounds, it was created to maintain a platform from
          which to consolidate respective professional experience in practice,
          research and teaching, post-graduation. We primarily operate through
          competitions to interrogate and test various spatial conditions, both
          individually and in collaboration with external practices.
        </p> <br />
        <p>
          <a href="mailto:info@ma-kan.com">info@ma-kan.com</a>
        </p>
      </div>
    </Modal>
  )
}
