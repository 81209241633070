import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GlobalStateContext } from "../../context/GlobalContextProvider"

import IconDescription from "../svg/IconDescription"
import IconCollections from "../svg/IconCollections"
import IconPencil from "../svg/IconPencil"
import IconBulb from "../svg/IconBulb"
import IconView from "../svg/IconView"
import Logo from "../svg/Logo"

import LogoButton from "./LogoButton"

import * as footerStyles from "./footer.module.scss"

const Footer = ({
  projectContent,
  toggleDescription,
  toggleGallery,
  toggleDrawings,
  togglePrecedents,
  togglePanorama,
  contentStatusDescription,
  contentStatusGallery,
  contentStatusDrawings,
  contentStatusPrecedents,
  contentStatusPanorama,
  visibilityDescription,
  visibilityGallery,
  visibilityDrawings,
  visibilityPrecedents,
  visibilityPanorama
}) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProjects(sort: { fields: orderNumber, order: ASC }) {
        edges {
          node {
            orderNumber
            slug
            icon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const state = React.useContext(GlobalStateContext)

  return (
    <footer className={footerStyles.center}>
      <div className={footerStyles.container}>
        <div className={footerStyles.icons}>
          {projectContent ? (
            <ul className={footerStyles.tabIcons}>

              {contentStatusDescription &&
                <li className={visibilityDescription ? (footerStyles.iconOn) : (footerStyles.icon)}>
                  <button onClick={toggleDescription}>
                    <IconDescription />
                  </button>
                </li>
              }

              {contentStatusGallery &&
                <li className={visibilityGallery ? (footerStyles.iconOn) : (footerStyles.icon)}>
                  <button onClick={toggleGallery}>
                    <IconCollections />
                  </button>
                </li>
              }

              {contentStatusDrawings &&
                <li className={visibilityDrawings ? (footerStyles.iconOn) : (footerStyles.icon)}>
                  <button onClick={toggleDrawings}>
                    <IconPencil />
                  </button>
                </li>
              }

              {contentStatusPrecedents &&
                <li className={visibilityPrecedents ? (footerStyles.iconOn) : (footerStyles.icon)}>
                  <button onClick={togglePrecedents}>
                    <IconBulb />
                  </button>
                </li>
              }

              {contentStatusPanorama &&
                <li className={visibilityPanorama ? (footerStyles.iconOn) : (footerStyles.icon)}>
                  <button onClick={togglePanorama}>
                    <IconView />
                  </button>
                </li>
              }

            </ul>
          ) : (
            <ul className={footerStyles.projectIcons}>
              {data.allContentfulProjects.edges.map(edge => {
                return (
                  <li key={edge.node.orderNumber} className={footerStyles.icon}>
                    <Link to={`/${edge.node.slug}`}>
                      <img
                        className={
                          state.theme === "randTheme"
                            ? footerStyles.iconLight
                            : footerStyles.iconDefault
                        }
                        src={edge.node.icon.file.url}
                        alt=" "
                      />
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
        </div>

        {/* <div className={footerStyles.logo}>
          <LogoButton />
          {projectContent && <Logo />}
        </div> */}
      </div>
    </footer>
  )
}

export default Footer
