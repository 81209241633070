import React from "react"
import Modal from "react-modal"

import IconClose from "../../svg/IconClose"

import * as modalStyles from "./modal.module.scss"

export default function CreditModal({ modalCreditState, modalCreditSet }) {

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    modalCreditSet(false)
  }

  return (
    <Modal
      isOpen={modalCreditState}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className={modalStyles.modal}
      overlayClassName={modalStyles.overlay}
      contentLabel="Credit Modal"
      ariaHideApp={false}
    >
      <div className={modalStyles.header}>
        <div className={modalStyles.close}>
          <button onClick={closeModal}>
            <IconClose />
          </button>
        </div>
      </div>

      <div className={modalStyles.content}>
        <h1>Credits</h1>
        <p>
          After years of pretending to work on this website, we finally agreed to put something together, much to the help of amazing web developer <a href="https://zephyrjames.dev" target="_blank" rel="noreferrer noopener">zephyrjames.dev</a> and graphic talent of <a href="https://www.instagram.com/byjive/?hl=en" target="_blank" rel="noreferrer">byjive</a>.
        </p> <br />
        <p>
          Website content can be monotonous and often outdated… if you would rather look at something new, stalk us on <a href="https://www.instagram.com/makandesignstudio/?hl=en" target="_blank" rel="noreferrer noopener">instagram</a> or <a href="https://www.facebook.com/makandesignstudio/" target="_blank" rel="noreferrer noopener">facebook</a>.
        </p>
      </div>
    </Modal>
  )
}
