import React from "react"

import Loader from "./Loader"

import Header from "./header/Header"
import Footer from "./footer/Footer"

import CreditModal from "./main/modal/CreditModal"
import InfoModal from "./main/modal/InfoModal"

import * as layoutStyles from "./layout.module.scss"

export default function Layout({
  projectContent,
  children,
  title,
  toggleDescription,
  toggleGallery,
  toggleDrawings,
  togglePrecedents,
  togglePanorama,
  contentStatusDescription,
  contentStatusGallery,
  contentStatusDrawings,
  contentStatusPrecedents,
  contentStatusPanorama,
  resetTabs,
  visibilityDescription,
  visibilityGallery,
  visibilityDrawings,
  visibilityPrecedents,
  visibilityPanorama,
}) {

  const [modalInfoState, modalInfoSet] = React.useState(false)
  function toggleModalInfo() {
    modalInfoSet(true)
  }

  const [modalCreditState, modalCreditSet] = React.useState(false)
  function toggleModalCredit() {
    modalCreditSet(true)
  }

  return (
    <div id="layout">
      <Header
        projectContent={projectContent}
        title={title}
        contentStatusDescription={contentStatusDescription}
        contentStatusGallery={contentStatusGallery}
        contentStatusDrawings={contentStatusDrawings}
        contentStatusPrecedents={contentStatusPrecedents}
        contentStatusPanorama={contentStatusPanorama}
        resetTabs={resetTabs}
        toggleModalInfo={toggleModalInfo}
        toggleModalCredit={toggleModalCredit}
      />
      <main className={layoutStyles.container}>
        {children}
        <InfoModal
          modalInfoState={modalInfoState}
          modalInfoSet={modalInfoSet}
        ></InfoModal>
        <CreditModal
          modalCreditState={modalCreditState}
          modalCreditSet={modalCreditSet}
        ></CreditModal>
      </main>
      <Footer
        projectContent={projectContent}
        toggleDescription={toggleDescription}
        toggleGallery={toggleGallery}
        toggleDrawings={toggleDrawings}
        togglePrecedents={togglePrecedents}
        togglePanorama={togglePanorama}
        contentStatusDescription={contentStatusDescription}
        contentStatusGallery={contentStatusGallery}
        contentStatusDrawings={contentStatusDrawings}
        contentStatusPrecedents={contentStatusPrecedents}
        contentStatusPanorama={contentStatusPanorama}
        visibilityDescription={visibilityDescription}
        visibilityGallery={visibilityGallery}
        visibilityDrawings={visibilityDrawings}
        visibilityPrecedents={visibilityPrecedents}
        visibilityPanorama={visibilityPanorama}
      />
    </div>
  )
}
