// extracted by mini-css-extract-plugin
export var center = "footer-module--center--YQYfF";
export var container = "footer-module--container--wjHmS";
export var hide = "footer-module--hide--FfWEy";
export var icon = "footer-module--icon--gr7qp";
export var iconDefault = "footer-module--iconDefault--SKfdC";
export var iconLight = "footer-module--iconLight--Tq9WF";
export var iconOn = "footer-module--iconOn--YSfja";
export var icons = "footer-module--icons--7tQzI";
export var logo = "footer-module--logo--5nyW8";
export var projectIcons = "footer-module--projectIcons--0d7iK";
export var tabIcons = "footer-module--tabIcons--uQXN0";